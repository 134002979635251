export const responsive = {
    ultraWide: {
        breakpoint: { max: 3000, min: 1920 },
        items: 1,
    },
    cloud: {
        breakpoint: { max: 3000, min: 1524 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 1524, min: 1048 },
        items: 1,
    },
    tabletPro: {
        breakpoint: { max: 1048, min: 576 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1,
    }
};