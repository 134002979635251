import React from "react";
import SEO from "../components/Seo/seo";
import styled from 'styled-components';
import { theme } from '../utils/theme';
import { responsive } from '../utils/responsive';
import FooterComponent from "../components/Home/Footer/FooterComponent";
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


// ---- Images ----

import HeadImage1 from '../components/imagesStock/Head/HeadImage1';
import HeadImage2 from '../components/imagesStock/Head/HeadImage2';
import HeadImage3 from '../components/imagesStock/Head/HeadImage3';
import HeadImage4 from '../components/imagesStock/Head/HeadImage4';
import HeadImage5 from '../components/imagesStock/Head/HeadImage5';


// ---- List Styles ----

export const StyledMainContainer = styled.div`
${theme.flex.centered};
min-height: 100vh;
margin: 0;
padding: 0;

    ${theme.media.cloud} {
       ${theme.flex.centeredColumn};
        height: 100%;
    }
    
    ${theme.media.tabletPro} {
        padding: 5%;
    }

    ${theme.media.mobile} {
        max-width: 100%;
    }
`
export const StyledLeftContainer = styled.div`
padding-left: 5%;

${theme.flex.centeredColumn};

${theme.media.cloud} {
       padding: 10%;
    }
`

export const StyledLeftContentContainer = styled.div`
justify-content: flex-start;
`

export const StyledTitle = styled.h1`
font-size: 4rem;
text-transform: uppercase;
padding: 5% 5% 0 5%;
margin: 0;
border-bottom: 1px solid #c8c7e2;
display: inline-block;

    ${theme.media.desktop} {
        margin-bottom: 0;
    }
    ${theme.media.tablet} {
        font-size: 3rem;
    }
    ${theme.media.mobile} {
        font-size: 2rem;
        justify-content: space-between;
    }
`

export const StyledDescription = styled.div`
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.5;
`

export const StyledButtonWrapper = styled.div`
display: flex;
flex-direction: row;
font-weight: 900;
width: 100%;
    
a {
    ${theme.buttonSubpage};

    ${theme.media.mobile} {
        padding: 0 20px;
    }
}

${theme.media.mobile} {
    justify-content: space-between;
    margin-right: 0;
}
`

export const StyledRightContainer = styled.div`
text-align: center;
height: 100%;
width: 70vw;
padding: 5%;
animation: fadein 4s ease-in;

button {
    margin: 5%;
}

   ${theme.media.cloud} {
       width: 100%;
       padding: 5%;
    }

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
`
export const StyledImageWrapper = styled.div`
    margin: 5%;

img {
    border-radius: 25px;
    ${theme.media.mobile} {
        border-radius: 10px;
    }
}
`


const HeadSubpage = (props) => {

    return (
        <Layout>
            <SEO title="Head" />

            <StyledMainContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledTitle>{props.uri.replace(/[/]/g, "")}</StyledTitle>

                        <StyledDescription>
                            <h1>Linen Lampshade</h1>

                            <p>
                                The lampshade's framework is constructed from brass mesh shaped with copper wires and covered with handmade paper adorned with sewn jute fabric.<br /><br />

                                Both the fabric and paper have been treated with a natural fireproof salt-based preparation.<br /><br />

                                Please click "CORE" to read further.
                            </p>

                        </StyledDescription>

                        <StyledButtonWrapper>
                            <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Back</h1></Link>
                            <Link title="This is a link to core subpage" as="a" to="/core" aria-label="core"><h1>Core</h1></Link>
                        </StyledButtonWrapper>

                    </StyledLeftContentContainer>

                </StyledLeftContainer>

                <StyledRightContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledImageWrapper>
                            <HeadImage1 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <HeadImage4 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <HeadImage2 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <HeadImage3 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <HeadImage5 />
                        </StyledImageWrapper>

                    </Carousel>

                </StyledRightContainer>

                <FooterComponent />

            </StyledMainContainer>

        </Layout>
    )
}

export default HeadSubpage;